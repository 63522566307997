import React, { useEffect, useState } from 'react'
import './Login.css'
import {Link ,useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from '../axios'

function Login() {
  const [email, setEmail] = useState(true)
  const [password, setPassword] = useState(true)
  const [form, setForm] = useState({email:"",password:""})

  const navigate = useNavigate()

  async function handleLoginForm(e) {
    const formData = {
      email: form.email,
      password: form.password
    }

    axios.post('/user/login',formData)
      .then(function(response) {
        console.log('Request successful');
        const user = response.data.user
        setForm({email:"",password:""});
        localStorage.setItem('token',user.token_value)
        localStorage.setItem('email',user.email)
        navigate(`/u/${user.first_name}_${user.last_name}/profile`)
      })
      .catch(function(error) {
        console.error('Request failed:', error);
        const error_msg = error.response.data.error
        toast.error("Incorrect Email ID or Password")
        if(error_msg==="email is not verified"){
          toast.warn("OTP sent to your mail")
          navigate(`/otp/${form.email}`)
        }
      });
  }

  useEffect(() => {
    if(localStorage.getItem('demo_user')) {
      navigate('/u/demo_user/home');
    }
    else{
    const userData = localStorage.getItem('token')

    if(userData){
      axios.post('/user/login',{token_value:userData})
      .then(({data})=>{
        const user = data.user
        localStorage.setItem('email',user.email)
        navigate(`/u/${user.first_name}_${user.last_name}/profile`)
      })
      .catch(()=>{
        localStorage.clear()
      })
    }}
  }, [navigate])
  

  return (
    <div className="page-element content">
        <main className="page-element form login">
            <h2 className="page-element title dark-accent-text">Log in</h2>
            <input 
              type="email" 
              placeholder="Email" 
              title="Email address matching your account goes here." 
              id="login-email-input" 
              className={`page-element ${(email) ? '':'error'}`} 
              value={form.email}
              onChange={(e)=>{setForm({...form,email:e.target.value})}} 
            />
            <input 
              type="password" 
              placeholder="Password" 
              title="Password matching your account goes here." 
              id="login-password-input" 
              className={`page-element ${(password) ? '':'error'}`} 
              value={form.password}
              onChange={(e)=>{setForm({...form,password:e.target.value})}} 
            />
            <button className="page-element button" type="submit" id="login-button" onClick={handleLoginForm}>Log in</button>
            <Link to="/password-recovery" className="page-element link" id="lost-password-link">Lost your password?</Link>
            <Link to="/registration" className="page-element link" id="create-account-link">Don't have an account yet?</Link>
        </main>
    </div>
  )
}

export default Login