import React from 'react'
import './SearchProfile.css'

function SearchProfile({sendRequest, deleteRequest, unFollow, showProfileModel, searchResult}) {
    
  return (
    <section className='app-element center invisible-scrollbar'>
      {searchResult.length === 0 && (
        <div className='pannel'>
          <p>Try Connecting to Users.</p>
        </div>
      )}
      {searchResult.map((user, index) => {
        return (
          <div className='pannel' key={index}>
            <div className='user-profile' onClick={() => showProfileModel(null, user.email)}>
              <img className='profile-picture' src={user.image || '/profilePicture-default.png'} alt="image"/>
              <div className='user-details'>
                <p>{`${user.first_name} ${user.last_name}`}</p>
                <p>{user.email}</p>
              </div>
            </div>
            <div>
              {user.connection === 'none' ? (
                <button onClick={() => sendRequest(user.email)}>Follow</button>
              ) : user.connection === 'requested' ? (
                <button onClick={() => deleteRequest(user.email, true)}>Cancel Requested</button>
              ) : (
                <button onClick={() => unFollow(user.email)}>Unfollow</button>
              )}
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default SearchProfile