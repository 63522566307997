import React, { useState } from 'react'
import './SideBar.css'

function SideBar({acceptRequest,deleteRequest,unFollow,deleteFollower,followers,followings,requests,showProfileModel}) {
    const [showFollowers, setShowFollowers] = useState(true)
    const [showFollowings, setShowFollowings] = useState(false)
    const [showRequests, setShowRequests] = useState(false)
    // console.log(followers)

    const renderData = (user)=>{
        return <div className='other-user' key={user.email}>
            <div onClick={()=>{showProfileModel(null,user.email)}}>
                <p>Name: {`${user.first_name} ${user.last_name}`}</p>
                <p>Email: {user.email}</p>
            </div>
            {showFollowers?<button onClick={()=>{deleteFollower(user.email)}} className='delete-user' ><i className='bx bx-plus rotated'></i></button>
            :showFollowings?<button onClick={()=>{unFollow(user.email)}} className='delete-user' ><i className='bx bx-plus rotated'></i></button>
            :<button onClick={()=>{deleteRequest(user.email)}} className='delete-user' ><i className='bx bx-plus rotated'></i></button>
            }
            {showRequests &&
            <button onClick={()=>{acceptRequest(user.email)}} className='accept-user' ><i className='bx bx-check'></i></button>
            }
        </div>
    }
  return (
    <section className='app-element side' id='ffr'>
        <div className='side-nav'>
            <button className={`${showFollowers?"":"disable"}`} onClick={()=>{
                setShowFollowers(true);
                setShowFollowings(false);
                setShowRequests(false);
            }}>Followers</button>
            <button className={`${showFollowings?"":"disable"}`} onClick={()=>{
                setShowFollowers(false);
                setShowFollowings(true);
                setShowRequests(false);
            }}>Followings</button>
            <button className={`${showRequests?"":"disable"}`} onClick={()=>{
                setShowFollowers(false);
                setShowFollowings(false);
                setShowRequests(true);
            }}>Requests</button>
        </div>
        <div className='side-body'>
            {showFollowers?followers.map(follower=>renderData(follower)):
            showFollowings?followings.map(following=>renderData(following)):
            requests.map(request=>renderData(request))}
        </div>
    </section>
  )
}

export default SideBar