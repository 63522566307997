import React, { useEffect, useState } from 'react'
import './AppNav.css'
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom'
import axios from '../axios.js'

function AppNav({getSearchResult,showProfileModel,unreadNotification,userData}) {
  const navigate = useNavigate()
  const user = userData.user
  const [dropdown, setDropdown] = useState(true)
  const [hamburger, setHamburger] = useState(true)
  const [searchData, setSearchData] = useState([])
  const [showProfile, setShowProfile] = useState('')
  const [showSearch, setShowSearch] = useState(false)

  const params = useParams()

  const loadSearchList = ()=>{
    axios.post('/user/searchUsers',{
      token:localStorage.getItem('token')
    }).then(({data})=>{
      setSearchData(data.data);
    }).catch(e=>console.log(e))
  }

  return (
    // <header id='appNavigation'>NAVBAR</header>
    <header className={`app-element ${showSearch && 'show-search'}`} id="appNavigation">
    <Link style={{}} to={generatePath("/u/:user/profile",params)} id="profile-link"><img src="/logo.png" className="app-element" alt="FamSketch" /></Link>
    <form autoComplete='off' onSubmit={
      e=>{
        const p =showProfile
      e.preventDefault(); 
      navigate(`/u/${params.user}?query=${encodeURIComponent(p)}`);
      getSearchResult(p)
      setSearchData([])
      setShowSearch(false)
      setShowProfile('')
    }} className={`app-element search `}>
      <input onClick={()=>{loadSearchList()}} value={showProfile} onChange={(e)=>{setShowProfile(e.target.value)}} className="app-element" type="text" placeholder="Search" id="search-input" />
      <button className="app-element" id="search-button"><i className="app-element bx bx-search"></i></button>
    </form>
    {searchData.length>0&&(
      <div className='app-element search search-list'>
        <button className='app-element close-search-list' onClick={()=>setSearchData([])}><i className='bx bx-plus rotated-45'></i></button>
        {searchData.map((searchUser,index)=>{return (
          <div className='app-element search-user' onClick={()=>{ showProfileModel(null,searchUser.email);setSearchData([])}} key={index}>
            <img src="/profilePicture-default.png" className="app-element search-user-profile" alt="userData.user.toLowerCase().replaceAll('_', ' ')" id="profile-picture"/>
            <div className='app-element search-name'>
              <p>{`${searchUser.first_name} ${searchUser.last_name}`}</p>
              <p>{searchUser.email}</p>
            </div>
          </div>
        )})}      
      </div>
    )}
    <nav className="app-element navigation">
      <Link onClick={()=>setShowSearch(!showSearch)} className="app-element link" id="search-link">
        {showSearch ?<i className="app-element bx bx-plus rotate-45"></i>:<i className="app-element bx bx-search"></i>}
      </Link>
      <Link to={generatePath("/u/:user/profile",params)} className="app-element link" id="home-link">
        <i className="app-element bx bxs-home"></i>
      </Link>
      <Link to={generatePath("/u/:user/inbox",params)} className="app-element link" id="inbox-link">
        <i className="app-element bx bxs-envelope"></i>
      </Link>
      <Link to={generatePath("/u/:user/calender",params)} className="app-element link" id="calendar-link">
        <i className="app-element bx bx-calendar"></i>
      </Link>
      <Link to={generatePath("/u/:user",params)} className="app-element link" id="hierarchy-link">
        <i className="app-element bx bx-sitemap"></i>
      </Link>
      <Link to={generatePath("/u/:user/ai",params)} className="app-element link" id="home-link">
        <i className='bx bx-brain'></i>
      </Link>
    </nav>
    <div className="app-element user">
      <div className="app-element user-activity" status="active" id="user-activity"></div>
      <Link to={generatePath("/u/:user/profile",params)} className="app-element" id="img-profile-link"><img src={user.image? user.image:'/profilePicture-default.png'} className="app-element profile-picture default-profile-picture" alt="userData.user.toLowerCase().replaceAll('_', ' ')" id="profile-picture"/></Link>
      <button className="app-element dropdown">
        <i onClick={()=>{setDropdown(!dropdown)}} className="app-element bx bxs-down-arrow"></i>
        <div  className={`app-element list ${dropdown?"hidden":""}`}>
          <Link onClick={()=>{setDropdown(true)}} to={generatePath("/u/:user/inbox",params)} className="app-element icon-text-wrapper link element">
            <i className="app-element bx bxs-video"></i>
            <p className="app-element title">Online room</p>
          </Link>
          <Link onClick={()=>{setDropdown(true)}} to={generatePath("/u/:user/notification",params)} className="app-element icon-text-wrapper link element">
            <i className='app-element bx bxs-bell'></i>
            <p className="app-element title">Notification {`(${unreadNotification})`}</p>
          </Link>
          <Link onClick={()=>{setDropdown(true)}} to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link element">
            <i className="app-element bx bxs-image"></i>
            <p className="app-element title">Album</p>
          </Link>
          <Link onClick={()=>{setDropdown(true)}} to={generatePath("/u/:user/chronicle",params)} className="app-element icon-text-wrapper link element">
            <i className="app-element bx bxs-book"></i>
            <p className="app-element title">Chronicle</p>
          </Link>
          <Link onClick={()=>{setDropdown(true)}} to={generatePath("/u/:user/memorial",params)} className="app-element icon-text-wrapper link element">
            <i className="app-element bx bxs-book-bookmark"></i>
            <p className="app-element title">Memorial</p>
          </Link>
          <Link onClick={()=>{setDropdown(true)}} to={generatePath("/u/:user/setting",params)} className="app-element icon-text-wrapper link element">
            <i className="app-element bx bxs-cog"></i>
            <p className="app-element title">Settings</p>
          </Link>
          <Link to="/" onClick={()=>{localStorage.clear(); setDropdown(true)}} className="app-element icon-text-wrapper link element">
            <i className="app-element bx bxs-log-out"></i>
            <p className="app-element title">Log out</p>
          </Link>
        </div>
      </button>
    </div>
    <div className="app-element hamburger-menu">
      <i onClick={()=>setHamburger(!hamburger)} className="app-element bx bx-menu"></i>
      <div className={`app-element menu ${hamburger?"hidden":""}`}>
        <button onClick={()=>setHamburger(!hamburger)} type="submit" className="app-element close-button">
          <i className="app-element bx bx-x"></i>
        </button>
        <Link onClick={()=>{setHamburger(true)}} to={generatePath("/u/:user",params)} className="app-element icon-text-wrapper link element user">
          <img alt='profilePicture-default' src="/profilePicture-default.png" className="app-element profile-picture default-profile-picture" id="profile-picture" />
          <p className="app-element title name">{params.user.toLowerCase().replaceAll('_', ' ')}</p>
        </Link>
        <Link onClick={()=>{setHamburger(true)}} to={generatePath("/u/:user/inbox",params)} className="app-element icon-text-wrapper link element">
          <i className="app-element bx bxs-video"></i>
          <p className="app-element title">Online room</p>
        </Link>
        <Link onClick={()=>{setDropdown(true)}} to={generatePath("/u/:user/notification",params)} className="app-element icon-text-wrapper link element">
            <i className='app-element bx bxs-bell'></i>
            <p className="app-element title">Notification</p>
          </Link>
        <Link onClick={()=>{setHamburger(true)}} to={generatePath("/u/:user",params)} className="app-element icon-text-wrapper link element">
          <i className="app-element bx bx-sitemap"></i>
          <p className="app-element title">Family tree</p>
        </Link>
        <Link onClick={()=>{setHamburger(true)}} to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link element">
          <i className="app-element bx bxs-image"></i>
          <p className="app-element title">Album</p>
        </Link>
        <Link onClick={()=>{setHamburger(true)}} to={generatePath("/u/:user/chronicle",params)} className="app-element icon-text-wrapper link element">
          <i className="app-element bx bxs-book"></i>
          <p className="app-element title">Chronicle</p>
        </Link>
        <Link onClick={()=>{setHamburger(true)}} to={generatePath("/u/:user/memorial",params)} className="app-element icon-text-wrapper link element">
          <i className="app-element bx bxs-book-bookmark"></i>
          <p className="app-element title">Memorial</p>
        </Link>
        <Link onClick={()=>{setHamburger(true)}} to={generatePath("/u/:user/setting",params)} className="app-element icon-text-wrapper link element">
          <i className="app-element bx bxs-cog"></i>
          <p className="app-element title">Settings</p>
        </Link>
        <Link onClick={()=>{localStorage.clear(); setHamburger(true)}} to="/" className="app-element icon-text-wrapper link element">
          <i className="app-element bx bxs-log-out"></i>
          <p className="app-element title">Log out</p>
        </Link>
      </div>
    </div>
  </header>
  )
}

export default AppNav