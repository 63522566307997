import React, { useState,useEffect } from 'react'
import './Chronicle.css'
import { Link, generatePath, useParams } from 'react-router-dom'
import translations from '../../../locales/translations.json';

function Chronicle() {
  const [displayName, setDisplayName] = useState(true)
  const params = useParams()

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);

  return (
    <div className='app-element chronicle-content content'>
      <div className='app-element chronicle'>
        <div className='app-element datewise invisible-scrollbar'>
          <div className='date'>
            <button id='prev'>{'<'}</button>
            <p id='date'>{"Day 20, March 2021"}</p>
            <button id='next'>{'>'}</button>
          </div>
          <img className='thumbnail' alt='event-image' src={currentLanguage === 'EN' ? '/flower_en.png' : '/flower_sv.png'} />
          <div className='date-note'>
            <p className='app-element participants-title'>Participants</p>
            <div className='app-element participants'>
              <img className='participant' src='/profilePicture-default.png' alt='profilePicture-default'/>
              <img className='participant' src='/profilePicture-default.png' alt='profilePicture-default'/>
              <img className='participant' src='/profilePicture-default.png' alt='profilePicture-default'/>
              <img className='participant' src='/profilePicture-default.png' alt='profilePicture-default'/>
            </div>
            <textarea className="app-element plan-note invisible-scrollbar" id="content" placeholder="Add notes" />
          </div>
        </div>
        <div className='app-element chronicle-data invisible-scrollbar'>
          <div className='app-element chronicle-title'>Wedding Ivana and Lukas</div>
          <div className='app-element chronicle-body invisible-scrollbar'>
            <p>
              Another milestone in our family took place on this Saturday. My beloved sister Ivanka married a great guy, I can honestly say that about him because he's my best friend and I'm glad she chose him. My entry in our family chronicle, best regards and have a nice day today, your František
            </p>
            <div>
            <img className='wedding' alt='event-image' src={currentLanguage === 'EN' ? '/flower_en.png' : '/flower_sv.png'} />
            <img className='wedding' alt='event-image' src={currentLanguage === 'EN' ? '/flower_en.png' : '/flower_sv.png'} />
            </div>
            <p>
              Yes, my beloved daughter got married and I also believe that she made a good choice. The wedding went great, the weather was good, the mood was great. In the other hand, the place was quite expensive. Anyway the memories will be more beautiful. Ivanka, I wish you good luck, health and well-being on the next path of your life, Your mom.
            </p>
            <div>
            <img className='ring' alt='event-image' src={currentLanguage === 'EN' ? '/flower_en.png' : '/flower_sv.png'} />
            
              <p>
                Your cousin Peter also joins the congratulations. The party was a rock and rolll In the end it madastre a bit tired and I couldn't manage it but you have super buddies. We enjoyed it. That Hanka is so nice, well and maybe, next time, you won't bother me with the question of when I'm going to get married too.
              </p>
            </div>
          </div>
        </div>
      </div>
      <nav className="app-element" id="sidebar">
        <button onClick={(e)=>{setDisplayName(!displayName)}} className="app-element toggle" id="toggle-pannel-left">
          <i className="app-element bx bxs-right-arrow" />
        </button>
        <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
            <i className="app-element bx bxs-image"></i>
            <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>Photos</p>
        </Link>
        <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
            <i className="app-element bx bxs-music"></i>
            <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>Audio</p>
        </Link>
        <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
            <i className="app-element bx bxs-video"></i>
            <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>Video</p>
        </Link>
        <Link to={generatePath("/u/:user/chronicle",params)} className="app-element icon-text-wrapper link">
            <i className="app-element bx bxs-book"></i>
            <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>Chronicle</p>
        </Link>
        <Link to={generatePath("/u/:user/memorial",params)} className="app-element icon-text-wrapper link">
            <i className="app-element bx bxs-book-bookmark"></i>
            <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>Memorial</p>
        </Link>
      </nav>
    </div>
  )
}

export default Chronicle