import React, { useState, useEffect } from 'react'
import './Album.css'
import { Link, generatePath, useParams } from 'react-router-dom'
import translations from '../../../locales/translations.json';

function Album() {
  const [displayName, setDisplayName] = useState(true)
  const params = useParams();
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);

  return (
    <div className='app-element content album-content'>
      <div className='album invisible-scrollbar'>
        <div className='album-pannel'>
          {/* If currentLanguage is english then flower_en.png else flower_sv.png */}
          <img className='' alt='event-image' src={currentLanguage === 'EN' ? '/flower_en.png' : '/flower_sv.png'} />
        </div>
        <div className='album-pannel'>
          <img className='' alt='event-image' src={currentLanguage === 'EN' ? '/princess_en.png' : '/princess_sv.png'} />
        </div>
        <div className='album-pannel'>
          <img className='' alt='event-image' src={currentLanguage === 'EN' ? '/iiteam_en.png' : '/iiteam_sv.png'} />
        </div>
      </div>
      <nav className="app-element" id="sidebar">
        <button onClick={(e) => { setDisplayName(!displayName) }} className="app-element toggle" id="toggle-pannel-left">
          <i className="app-element bx bxs-right-arrow" />
        </button>
        <Link to={generatePath("/u/:user/album", params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-image"></i>
          <p className={`app-element title ${displayName ? "hidden" : "shown"} toggled`}>Photos</p>
        </Link>
        <Link to={generatePath("/u/:user/album", params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-music"></i>
          <p className={`app-element title ${displayName ? "hidden" : "shown"} toggled`}>Audio</p>
        </Link>
        <Link to={generatePath("/u/:user/album", params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-video"></i>
          <p className={`app-element title ${displayName ? "hidden" : "shown"} toggled`}>Video</p>
        </Link>
        <Link to={generatePath("/u/:user/chronicle", params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-book"></i>
          <p className={`app-element title ${displayName ? "hidden" : "shown"} toggled`}>Chronicle</p>
        </Link>
        <Link to={generatePath("/u/:user/memorial", params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-book-bookmark"></i>
          <p className={`app-element title ${displayName ? "hidden" : "shown"} toggled`}>Memorial</p>
        </Link>
      </nav>
    </div>
  )
}

export default Album