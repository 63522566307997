let data = [
  {
    subject: "What makes FamSkatch different?",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime. Lorem ipsum dolor sit amet.",
    id: 1
  },
  {
    subject: "How do I register?",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
    id: 2
  },
  {
    subject: "What are the risks I take when signing up?",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    id: 3
  },
  {
    subject: "I think the profile page of the app needs to change.",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "Maxime. Lorem ipsum dolor sit amet.",
    id: 4
  },
  {
    subject: "They should do this site in different color modes too.",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime. Lorem ipsum dolor sit amet.",
    id: 5
  },
  {
    subject: "Lorem ipsum dolor sit amet.",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime. Lorem ipsum dolor sit amet.",
    id: 6
  },
  {
    subject: "Lorem ipsum dolor sit amet.",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime. Lorem ipsum dolor sit amet.",
    id: 7
  }
]

export const useFAQData = () => {
  return data
}
