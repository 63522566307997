import React, { useState } from 'react'
import './Index.css'
import { Link, generatePath, useParams } from 'react-router-dom'


function Index() {
    const params = useParams();
    const [displayName, setDisplayName] = useState(false)

  return (
    <div className="app-element Index_content content">
        <main className="app-element familytree">
        <div className="app-element member">
            <img alt='profilePicture-default' src="/profilePicture-default.png" className="app-element profile-picture default-profile-picture" />
            <p className="app-element name">Name Surname</p>
            <p className="app-element title">Grandpa</p>
            <p className="app-element birth info"><span className="app-element tag">Born:</span> DD.MM.YYYY</p>
            <p className="app-element location info"><span className="app-element tag">City:</span> Košice</p>
            <p className="app-element relationship-status info"><span className="app-element tag">Status:</span> Single</p>
        </div>
        </main>
        <nav className="app-element" id="sidebar">
            <button onClick={(e)=>{setDisplayName(!displayName)}} className="app-element toggle" id="toggle-pannel-left">
                <i className="app-element bx bxs-right-arrow" />
            </button>
            <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
                <i className="app-element bx bxs-image"></i>
                <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>Photos</p>
            </Link>
            <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
                <i className="app-element bx bxs-music"></i>
                <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>Audio</p>
            </Link>
            <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
                <i className="app-element bx bxs-video"></i>
                <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>Video</p>
            </Link>
            <Link to={generatePath("/u/:user/chronicle",params)} className="app-element icon-text-wrapper link">
                <i className="app-element bx bxs-book"></i>
                <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>Chronicle</p>
            </Link>
            <Link to={generatePath("/u/:user/memorial",params)} className="app-element icon-text-wrapper link">
                <i className="app-element bx bxs-book-bookmark"></i>
                <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>Memorial</p>
            </Link>
        </nav>
    </div>
  )
}

export default Index