import React, { useEffect, useState } from 'react'
import './Notification.css'
import axios from '../../../axios'

function Notification({showPostModel,clearNotification,deleteNotification,markNotification,getUnreadNotification,getNotification,unreadNotification,notification}) {
  const token = localStorage.getItem('token')
  const email = localStorage.getItem('email')
  
  const [showUnread, setShowUnread] = useState(false)

  const  getPost = (postID)=>{
    axios.post('/user/get_single_post',{token:token, post:postID, user_email:email})
    .then(res=>showPostModel(res.data,postID))
    .catch(e=>console.log(e))
  }

  useEffect(() => {
    getNotification()
    getUnreadNotification()
    // console.log(notification)
  }, [])
  
  return (
    <div className='app-element notification-content'>
      <div className='notification'>
        {showUnread?
          (unreadNotification.length>0 ? unreadNotification.map((notify,index)=>{
          return <div className={`pannel ${!notify.read && 'unread'}`} key={index}>
          <p onClick={()=>{
            markNotification(notify.interaction)
            getPost(notify.post)
          }}>{notify.notification}</p>
          <button onClick={()=>{deleteNotification(notify.interaction)}}><i className='bx bx-plus rotated-45'></i></button>
          </div>
          }): <div className='pannel'>No Unread Notification to show.</div>):
          (notification.length>0 ? notification.map((notify,index)=>{
          return <div className={`pannel ${!notify.read && 'unread'}`} key={index}>
          <p onClick={()=>{
            if(!notify.read) markNotification(notify.interaction)
            getPost(notify.post)
          }}>{notify.notification}</p>
          <button onClick={()=>{deleteNotification(notify.interaction)}}><i className='bx bx-plus rotated-45'></i></button>
          </div>
          }): <div className='pannel'>No Notification to show.</div>)
        } 
      </div>
      <div className='right'>
        {showUnread ? 
          <button onClick={()=>setShowUnread(false)}>Show All Notification</button>:
          <button onClick={()=>setShowUnread(true)}>Show Unread Notification</button>
        }
        <button onClick={()=>clearNotification()}>Clear All Notification</button>
      </div>
    </div>
  )
}

export default Notification