import React, { useState } from 'react'
import './Inbox.css'
import {Link, generatePath, useParams} from 'react-router-dom'
import {useDemoData} from '../../../composables/demo'

function Inbox() {
  const userData = useParams()
  const messages = useDemoData().inbox
  const users = useDemoData().users.slice(1)
  const [displayName, setDisplayName] = useState(true)

  function handlePannel(e) {
    e.target.parentElement.parentElement.querySelectorAll(".toggled").forEach(element => {
      console.log(element.classList)
      if (element.classList.contains("hidden")) {
        element.classList.remove("hidden")
        element.classList.add("shown")
      } else if (element.classList.contains("shown")) {
        element.classList.remove("shown")
        element.classList.add("hidden")
      }
    });
  }
  return (
  <div className="app-element Inbox_content content" >
    <section className="app-element pannel invisible-scrollbar" id="users">
      <button type="submit" onClick={(e)=>{setDisplayName(!displayName)}} className="app-element toggle" id="toggle-pannel-left"><i className="app-element bx bxs-right-arrow"></i></button>
      {users.map(user=>{return (
        <div className="app-element user" key={user.params.id} id={user.params.id}>
          <img alt='profilePicture-default' src="/profilePicture-default.png" className="app-element profile-picture default-profile-picture" />
          <p className={`app-element toggled ${displayName?"hidden":"shown"} name`}>{user.params.user.toLowerCase().replaceAll("_", " ")}</p>
          <div className="app-element user-activity" status="active"></div>
        </div>
      )})}
      
    </section>
    <section className="app-element center invisible-scrollbar">
      <div className="app-element links">
        <Link to={generatePath("/:user/inbox",userData)} className="app-element link">
          <i className="app-element link bx bx-video"></i>
        </Link>
        <Link to={generatePath("/:user/inbox",userData)} className="app-element link">
          <i className="app-element link bx bx-joystick"></i>
        </Link>
      </div>
      <div className="app-element" id="inbox">
      {messages.map((message,index)=>{return (
        <article className="app-element message" key={index}>
          <header className="app-element info">
            <img alt='profilePicture-default' src="/profilePicture-default.png" className="app-element profile-picture default-profile-picture" />
            <p className="app-element author">{message.from.params.user.toLowerCase().replaceAll("_", " ")}</p>
            <p className="app-element time">{message.at}</p>
          </header>
          <p className="app-element message-content">{message.content}</p>
        </article>
      )})}
      </div>
    </section>
    <section className="app-element pannel invisible-scrollbar" id="selected-user">
      <button type="submit" className="app-element toggle" id="toggle-pannel-right"><i onClick={e=>handlePannel(e)} className="app-element bx bxs-left-arrow"></i></button>
      <div className="app-element toggled hidden user-info">
        <img src="/profilePicture-default.png" className="app-element profile-picture default-profile-picture toggled hidden" />
        <h2 className="app-element name  toggled hidden">{userData.user.toLowerCase().replaceAll("_", " ")}</h2>
        <p className="app-element people icon-text-wrapper toggled hidden">
          <i className="app-element bx bxs-group"></i>
          <span id="number">###</span>
        </p>
        <div className="app-element info toggled hidden">
          <p className="app-element birth info"><span className="app-element tag">Born:</span> DD.MM.YYYY</p>
          <p className="app-element location info"><span className="app-element tag">City:</span> Košice</p>
          <p className="app-element relationship-status info"><span className="app-element tag">Status:</span> Single</p>
        </div>
        <div className="app-element social toggled hidden">
          <a href="" className="app-element function">
            <i className="app-element bx bx-male-female"></i>
          </a>
          <Link to="{name: 'user-id-calendar', params: userData}" className="app-element link">
            <i className="app-element bx bx-calendar"></i>
          </Link>
          <a href="" className="app-element function">
            <i className="app-element bx bx-heart"></i>
          </a>
          <a href="" className="app-element function">
            <i className="app-element bx bx-share-alt"></i>
          </a>
        </div>
        <p className="app-element quote toggled hidden">We are memories for our loved ones.</p>
      </div>
    </section>
  </div>
  )
}

export default Inbox